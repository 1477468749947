@mixin bd-radius($value) {
	-webkit-border-radius: $value;
	-moz-border-radius: $value;
	border-radius: $value;
}
@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
    -webkit-transition: $what $time $how;
    -moz-transition:    $what $time $how;
    -ms-transition:     $what $time $how;
    -o-transition:      $what $time $how;
    transition:         $what $time $how;
}
