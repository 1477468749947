@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?b81baq');
  src:  url('../fonts/icomoon.eot?b81baq#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?b81baq') format('truetype'),
    url('../fonts/icomoon.woff?b81baq') format('woff'),
    url('../fonts/icomoon.svg?b81baq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal; 
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e922";
}
.icon-edit2:before {
  content: "\e91e";
}
.icon-edit3:before {
  content: "\e91f";
}
.icon-tick:before {
  content: "\e920";
}
.icon-checked:before {
  content: "\e921";
}
.icon-arrow-down:before {
  content: "\e91d";
}
.icon-logo:before {
  content: "\e91c";
}
.icon-reco-logo:before {
  content: "\e90d";
}
.icon-clock:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-edit:before {
  content: "\e90b";
}
.icon-play1:before {
  content: "\e91b";
}
.icon-calendar-time:before {
  content: "\e902";
}
.icon-copy:before {
  content: "\e904";
}
.icon-current-setting:before {
  content: "\e905";
}
.icon-dashboard:before {
  content: "\e906";
}
.icon-delete:before {
  content: "\e907";
}
.icon-disconnect:before {
  content: "\e908";
}
.icon-document:before {
  content: "\e909";
}
.icon-download:before {
  content: "\e90a";
}
.icon-email:before {
  content: "\e90c";
}
.icon-exit:before {
  content: "\e90e";
}
.icon-headphone:before {
  content: "\e90f";
}
.icon-integration:before {
  content: "\e910";
}
.icon-meeting-notes:before {
  content: "\e911";
}
.icon-notes-edit:before {
  content: "\e912";
}
.icon-play:before {
  content: "\e913";
}
.icon-search:before {
  content: "\e914";
}
.icon-setting:before {
  content: "\e915";
}
.icon-share:before {
  content: "\e916";
}
.icon-storage:before {
  content: "\e917";
}
.icon-subscribe:before {
  content: "\e918";
}
.icon-unmute:before {
  content: "\e919";
}
.icon-user:before {
  content: "\e91a";
}
