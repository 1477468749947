@media (max-width:1840px){}
@media (max-width:1730px){
    .rt-statistics {grid-gap: 20px;}
}
@media (max-width:1680px){}
@media (max-width:1600px){}
@media (max-width:1560px){
    .at-pricingcombinationholder {
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
    .rt-streamingproviders {grid-gap: 20px;}
    .rt-linkedsupportholder {
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

        .rt-contentbox {padding: 20px;}
    }
    .rt-statistic {padding: 20px;}
}
@media (max-width:1440px){
    .at-pricingtablecontent {
        ul {font-size: 14px;}
    }
    .rt-manageprofileform {padding: 30px;}
    .rt-threecols {
        margin: 0 -10px;

        .form-group {
            width: calc(33.33% - 20px);
            margin: 0 10px 20px !important;
        }
    }
    .rt-statistics {grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));}
}
@media (max-width:1372px){
    .rt-statisticicon {
        width: 70px;
        height: 70px;
        font-size: 32px;
        line-height: 70px;
    }
    .rt-statisticcontent {
        padding: 1px 0;
        width: calc(100% - 80px);
    }
}
@media (max-width:1349px){}
@media (max-width:1280px){
    .rt-profileimageholder {
        max-width: 205px;
        margin: 0 30px 0 0;
    }
    .rt-profileimage {
        width: 200px;
        height: 200px;
    }
    .rt-selectimage {
        label {
            padding: 0 15px;
            max-width: 200px;
        }
    }
    .rt-profilebox {
        width: calc(100% - 235px);

        .rt-twocols {
            margin: 0 -10px;

            .form-group {
                width: calc(50% - 20px);
                margin: 0 10px 20px !important;
            }
        }
    }
    .rt-manageprofileform {padding: 25px;}
}
@media (max-width:1200px){}
@media (max-width:1199px){
    .rt-dpuploadbox {
        float: none;
        margin: 0 auto;
    }
    .rt-threecols {
        .form-group {
            width: calc(100% - 20px);
            margin: 0 10px 20px !important;
        }
    }
    .rt-manageprofileform {padding: 20px;}
    .rt-statistics {grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));}
    .rt-profileimageholder{
        max-width: 100%;
        margin: 0 0 30px;
    }
    .rt-profilebox{width: 100%;}
    .rt-profileimage{
        float: none;
        width: 180px;
        height: 180px;
    }
    .rt-selectimage {
        label {
            padding: 0;
            font-size: 15px;
            max-width: 180px;
            line-height: 44px;
            @include bd-radius(6px);
        }
    }
}
@media (max-width:1080px){
    .rt-pricingcombinationholder {
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
    .rt-lateststream{
        .rt-rightarea{
            width: 100%;
            margin: 20px 0 0;
        }
    }
}
@media (max-width:1024px){}
@media (max-width:991px){}
@media (max-width:900px){
    .rt-themehead {
        margin: 0 0 20px;
        h2{
            font-size: 26px;
            line-height: 30px;
        }
    }
    .rt-topbar {
        .rt-logo {
            width: 150px;
            margin: 6px 0;
        }
    }
    .rt-sidebarnavigation{width: 150px;}
    .rt-sidebarnav{
        font-size: 14px;

        ul{
            li{
                a{padding: 10px 20px;}
            }
            li.rt-active {
                > a:after {
                    border-right: 10px solid #EDEDED;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                }
            }
        }
    }
    .rt-wrapper {padding: 90px 15px 30px 170px;}
    .rt-linkedsupportholder {
        .rt-contentbox {min-height: auto;}
    }
    .rt-pricingtabelehead {
        padding: 20px 0;
        min-height: 163px;

        h2 {
            font-size: 36px;
            line-height: 40px;
        }
        em {
            font-size: 18px;
            line-height: 21px;
        }
        h3 {
            font-size: 18px;
            line-height: 21px;
        }
    }
    .rt-pricingtablecontent {
        ul {
            font-size: 14px;
            li {
                padding: 15px 0;
                h3 {
                    font-size: 16px;
                    line-height: 19px;
                }
                &:first-child{padding: 20px 0;}
            }
        }
    }
    .rt-pricingtablebox {min-height: 430px;}
    .rt-subscribehead {
        h2 {
            font-size: 30px;
            line-height: 36px;

            span{font-size: 14px;}
        }
    }
    .rt-btncouponcode {
        font-size: 14px;
        line-height: 34px;
    }
    .rt-subscriptionplan {
        h3 {
            font-size: 20px;
            line-height: 23px;
        }
    }
    .rt-subscriptionplan {
        ul {
            li {
                span {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }
    .rt-subscriptionplan {
        .rt-btnbox{
            .rt-btn{
                font-size: 14px;
                line-height: 36px;
            }
        }
    }
    .rt-subscribe{
        .rt-description{
            p{font-size: 14px;}
        }
    }
}
@media (max-width:850px){
    .rt-themetablebox{

        .table {
            thead {display: none;}
            tbody{
                tr {
                    td {
                        display: block;
                        position: relative;
                        text-align: left !important;
                        padding: 5px 20px 5px calc(50% + 20px);

                        p{
                            width: 100%;
                            float: none;
                            display: inline-block;
                            vertical-align: middle;
                        }

                        &:before {
                            top: 0;
                            left: 0;
                            width: 50%;
                            height: 100%;
                            font-size: 14px;
                            padding: 5px 20px;
                            position: absolute;
                            line-height: inherit;
                            content: attr(data-title);
                        }
                        &:first-child:before{line-height: 18px;}
                        &:nth-child(2):before,
                        &:nth-child(3):before{line-height: 20px;}
                        &:nth-child(4):before,
                        &:nth-child(5):before{line-height: 36px;}
                    }
                }
            }
        }
    }
}
@media (max-width:800px){}
@media (max-width:767px){
    .rt-filtermodalmodal {
        .modal-dialog{
            max-width: 100%;
            margin: 1.75rem 20px;
        }
    }
    .rt-profilebox {

        .rt-twocols {
            margin: 0 -10px;

            .form-group {
                width: calc(100% - 20px);
                margin: 0 10px 20px !important;
            }
        }
    }
}
@media (max-width:640px){
    .rt-formbox {
        width: auto;
        margin: 0 30px;
        max-width: 100%;
        padding: 50px 30px;
    }
    /* .rt-searchform {
        .rt-twocols {
            margin: 0;
            .form-group {
                width: 100%;
                margin: 0 0 15px !important;

                &:last-child{margin: 0 !important;}
            }
        }
    } */
    .rt-pagination {
        font-size: 14px;
        line-height: 42px;

        ul {
            li {
                a,
                span{
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
    .rt-thememodal {
        .modal-dialog {margin: 1.75rem 20px;}
    }
    .rt-thememodalcontent {
        .rt-btnbox {
            .rt-btn {width: 150px;}
        }
    }
    .rt-searchform{
        float: left;
        max-width: 100%;
    /*  .rt-threecols{
    
            .form-group{
    
                &:first-child{
                    width: calc(100% - 16px);
                    margin: 0 8px 15px !important;
                }
                &:nth-child(3){
                    float: right;
                    width: calc(23% - 16px);
                }
            }
        } */
    }
    .rt-filtermodalform{
        .rt-twocols{
            margin: 0;

            .form-group{
                width: 100%;
                margin: 0 0 20px !important;
            }
        }
    }
}
@media (max-width:568px){
    .rt-wrapper {padding: 90px 15px 30px 15px;}
    .rt-header {padding: 15px;}
    .rt-sidebarnavigation{
        width: 0;
    }
    .rt-showmenusidebar{
        width: 200px;

        .rt-sidebarnav{
            ul {
                li {
                    a {padding: 12px 25px;}
                }
            }
        }
    }
    .rt-downloadcontent {
        padding: 15px;

        i {
            font-size: 50px;
            line-height: 50px;
        }
        h3 {
            font-size: 15px;
            line-height: 19px;
        }
    }
    .rt-statisticicon {
        width: 55px;
        height: 55px;
        font-size: 24px;
        line-height: 55px;
    }
    .rt-statisticcontent {
        h2{
            font-size: 24px;
            line-height: 24px;

            em{padding: 0 0 10px;}
        }
    }
}
@media (max-width:535px){
    .rt-linkedsupportholder {grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));}
}
@media (max-width:480px){
    .rt-formbox {
        padding: 40px 30px;
        margin: 0 15px;

        .rt-logo {width: 200px;}
        .rt-formtheme {
            fieldset {
                .rt-btn {
                    font-size: 16px;
                    line-height: 50px;
                }
                .form-group {

                    span {font-size: 15px;}
                }
                .form-group:nth-child(3),
                .form-group:nth-child(4) {margin: 0 0 30px;}
                .form-control {
                    height: 60px;
                    font-size: 14px;
                }
            }
        }
    }
    .rt-loginlogobox {margin: 0 0 50px;}
    .rt-pricingtabelehead {
        padding: 20px 0;
        min-height: 163px;

        h2 {
            font-size: 32px;
            line-height: 46px;
        }
        em {
            font-size: 16px;
            line-height: 19px;
        }
        h3 {
            font-size: 16px;
            line-height: 19px;
        }
    }
    .rt-priceswitch {
        width: 35px;

        .rt-priceswitch-label {
            height: 20px;
            line-height: 20px;

            &:before{
                top: 3px;
                left: 3px;
                width: 14px;
                height: 14px;
            }
        }
    }
}
@media (max-width:479px){}
@media (max-width:420px){
    .rt-userprofileimg {
        width: 35px;
        height: 35px;
        margin: 0 10px 0 0;
    }
    .rt-btndropdown {
        font-size: 14px;
        line-height: 17px;
    }
    .rt-btnopensidebarmenu {
        font-size: 18px;
        margin: 7px 0 7px 15px;
    }
    .rt-topbar {
        .rt-logo {
            width: 110px;
            margin: 7px 0;
        }
    }
    .rt-themehead{
        h2 {
            font-size: 22px;
            line-height: 26px;
        }
    }
    .rt-themetitle{
        h2 {
            font-size: 16px;
            line-height: 19px;
        }
    }
    .rt-linkedbox{
        ul {
            li {
                > span {
                    font-size: 14px;
                    line-height: 17px;
                }
                a:first-child{
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }
    .rt-sidebarnavigation{top: 65px;}
    .rt-description {
        p {
            font-size: 14px;
            line-height: 24px;
        }
    }
    .rt-btn{
        font-size: 14px;
        line-height: 40px;
    }
    .rt-subscribehead {
        h2 {
            font-size: 22px;
            line-height: 30px;
        }
    }
    .rt-subscriptionplan {
        h3 {font-size: 16px;}
    }
    .rt-dpuploadbox {
        width: 150px;
        height: 150px;
    }
    .rt-statistics {grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));}
    .rt-pagination {
        ul {
            li {
                a,
                span {
                    width: 35px;
                    height: 40px;
                }
            }
        }
    }
}
@media (max-width:414px){}
@media (max-width:400px){

    .rt-formbox {padding: 40px 15px;}
}
@media (max-width:374px){
    .rt-header {padding: 10px;}
    .rt-btndropdown {
        font-size: 12px;
        line-height: 15px;

        em {width: 100px;}
    }
    .rt-userprofileimg {
        width: 30px;
        height: 30px;
        margin: 0 5px 0 0;
    }
    .rt-btnopensidebarmenu {margin: 6px 0 6px 10px;}
    .rt-topbar {
        .rt-logo {margin: 6px 0;}
    }
    .rt-wrapper {padding: 70px 15px 30px 15px;}
    .rt-sidebarnavigation{top: 53px;}
}