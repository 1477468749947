
/*
	@Author: Renesis Technologies
	@URL: https://renesistech.com/
	This file contains the styling for the actual theme, this is the file you need to edit to change the look of the theme.

	This files contents are outlined below.
	01.	Theme Reset Style
	02.	Global Elements
	03.	Menu
	04.	Social
	05.	Article
	06.	Posts
	07.	Responsive
	08.	Widgets
	09.	Flexslider
	10.	Grid
*/

/* =============================================
			Theme Reset Style
============================================= */
@import "assets/css/variables";
@import "assets/css/mixin";
* {
	margin: 0px;
	padding: 0px;
}
*:after,
*:before{ @include transition(all,0.2s,ease-out); }
@font-face {
	font-family: "Roboto-Regular";
	src: url("assets/fonts/Roboto/Roboto-Regular.eot");
	src: url("assets/fonts/Roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), 
	url("assets/fonts/Roboto/Roboto-Regular.woff2") format("woff2"), 
	url("assets/fonts/Roboto/Roboto-Regular.woff") format("woff"), 
	url("assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Roboto-Medium";
	src: url("assets/fonts/Roboto/Roboto-Medium.eot");
	src: url("assets/fonts/Roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
	url("assets/fonts/Roboto/Roboto-Medium.woff2") format("woff2"), 
	url("assets/fonts/Roboto/Roboto-Medium.woff") format("woff"), 
	url("assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Roboto-Bold";
	src: url("assets/fonts/Roboto/Roboto-Bold.eot");
	src: url("assets/fonts/Roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), 
	url("assets/fonts/Roboto/Roboto-Bold.woff2") format("woff2"), 
	url("assets/fonts/Roboto/Roboto-Bold.woff") format("woff"), 
	url("assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Renogare";
	src: url("assets/fonts/Renogare/Renogare-Regular.eot");
	src: url("assets/fonts/Renogare/Renogare-Regular.eot?#iefix") format("embedded-opentype"),
	url("assets/fonts/Renogare/Renogare-Regular.woff2") format("woff2"), 
	url("assets/fonts/Renogare/Renogare-Regular.woff") format("woff"), 
	url("assets/fonts/Renogare/Renogare-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
body {
	color: #666;
	background:$whisper;
    font:400 14px/23px 'Roboto', Arial, Helvetica, sans-serif;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #0f0f0f;
        margin: 0 0 8px;
        font-weight: 400;
        line-height: 1.5;
        font-style: normal;
        text-transform: capitalize;
        font-family: 'Roboto', Arial, Helvetica, sans-serif;
    }
}
h1 { font-size: 48px;}
h2 { font-size: 36px;}
h3 { font-size: 24px;}
h4 { font-size: 18px;}
h5 { font-size: 16px;}
h6 { font-size: 14px;}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a { color: #0f0f0f;}
p a{ @include transition(all,0.2s,ease-out); }
p a,
p a:hover { text-decoration:underline; }
p {
	margin: 0 0 20px;
	line-height: 20px;
	letter-spacing: normal;
}
a {
	text-decoration: none;
    @include transition(all,0.2s,ease-out);

    &:hover,
    &:focus,
    &:active {
        outline: none;
        text-decoration: none;
    }

}
ul { margin: 0; }
figure {
	margin-bottom: 6px;
	position: relative;
}
img {
	height: auto;
	max-width: 100%;
}
strong { color: #414141; }
em {font-style: normal;}
iframe {
	width: 100%;
	float: left;
	border: none;
}
button { border: none; }
textarea,
select,
.rt-select select,
.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
	color: #666;
	outline: none;
	height: 40px;
	background:#fff;
	font-size: 14px;
	-webkit-box-shadow: none;
			box-shadow: none;
	line-height: 18px;
	padding: 10px 20px;
	border-radius: 4px;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid $lightgrey;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
textarea,
.form-control{
	resize: none;
	height: 210px;
}
.form-control::-webkit-input-placeholder { color: #666; } /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: #666; } /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: #666; } /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: #666; } /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder { color:#666; } /* Microsoft Edge */
input:focus,
.select select:focus,
.form-control:focus {
	color: #666;
	border-color: #666;
	-webkit-box-shadow:none !important;
			box-shadow:none !important;
}
.form-group {
	width: 100%;
	float: left;
	margin:0 0 20px;
    position: relative;
}
label {
	color: #666;
	display: block;
	font-weight: 400;
	margin-bottom: 10px;
}
form p {
	float: left;
	position: relative;
    width: 100%;

    p span i {
        top: 13px;
        left: 16px;
        color: #474747;
        position: absolute;
    }
}
option { padding: 5px 15px; }
li {
	line-height: 28px;
	list-style: disc inside none;
}
ul ul { margin-left: 20px; }
ol {
	float: none;
	list-style: decimal;
    padding-left: 15px;

    li {
        list-style: decimal;
        width: 100%;
    }
}
ol ol { margin-left: 20px; }
table {
	background-color: transparent;
	max-width: 100%;
}
th { text-align: left; }
table {
	width: 100%;
	margin-bottom: 15px;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
	border-top: 1px solid #dbdbdb;
	border: 1px solid #dbdbdb;
	line-height: 2.5;
	padding-left: 3px;
	text-align: center;
	vertical-align: top;
}
table thead tr th {
	border-top: 1px solid #dbdbdb;
	text-align: center;
	text-transform: capitalize;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td { border-top: 1px solid #dbdbdb; }
table > thead > tr > th {
	border-bottom: 2px solid #dbdbdb;
	vertical-align: bottom;
}
table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td { border-bottom: 0; }
table > tbody + tbody { border-top: 2px solid #dbdbdb; }
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td { padding: 6px 10px; }
p ins { color: #666; }
dl dd { margin-left: 20px; }
address { font-style: italic; }
/* =============================================
			Theme Custom Select
============================================= */
.rt-select {
	color:#666;
	float:left;
	width: 100%;
	position: relative;
    text-transform:uppercase;

    &:after {
        top: 0;
        z-index: 2;
        right: 15px;
        color: #666;
        display: block;
        font-weight: 900;
        content: '\f078';
        line-height: 40px;
        position: absolute;
        text-align: center;
        font-size: inherit;
        pointer-events: none;
        font-family: 'Font Awesome 5 Free';
    }

    select {
        z-index: 1;
        width: 100%;
        position: relative;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        option { color:#666; }
    }
}
/* =============================================
		Theme Custom CheckBox Radio
============================================= */
.rt-radio,
.rt-checkbox{

    margin:0;
    width: 100%;
    float: left;
    position: relative;

    label{
        margin:0;
        width: 100%;
        float: left;
        display:block;
        cursor:pointer;
        position: relative;
        padding:0 0 0 20px;
    }
}
.rt-radio input[type=radio],
.rt-checkbox input[type=checkbox] { display:none; }
.rt-radio input[type=radio] + label:before,
.rt-checkbox input[type=checkbox] + label:before {
    top: 0;
    left: 0;
    z-index: 99;
    content: '';
    color: $white;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    text-align: center;
    position: absolute;
    font-family: 'Font Awesome 5 Free';
}
.rt-radio input[type=radio]:checked + label:before,
.rt-checkbox input[type=checkbox]:checked + label:before {content:'\f02e';}
/* =============================================
			Theme Global Elements
============================================= */
.rt-haslayout {
	width:100%;
	float:left;
}
.rt-sectionspace {padding: 100px 0;}
.rt-locationmap {
	width: 100%;
	float: left;
	height: 500px;
}
.rt-btn-sm {
	padding: 0 20px;
	line-height: 30px;
}
.rt-btn {
    padding: 0 26px;
	position: relative;
	text-align: center;
	color: $white;
	display: inline-block;
	background: $redorange;
    vertical-align: middle;
    @include bd-radius(6px);
	text-transform: capitalize;
    font:500 16px/43px 'Roboto', Arial, Helvetica, sans-serif;

    &:hover,
    &:focus {
        color:$white;
        background: $harleydavidsonorange;
    }
    &:focus{outline: none;}

    i,
    span{
        display: inline-block;
        vertical-align: middle;
    }
}
.rt-btn-lg { width: 100%;}
.rt-socialicons {
	overflow: hidden;
	list-style: none;
	text-align:center;
    line-height: 34px;

    li {
        float: left;
        padding: 0 4px;
        line-height: inherit;
        list-style-type: none;

        a {
            width: 34px;
            height: 34px;
            color: $white;
            display:block;
            background:#000;
            overflow:hidden;
            font-size: 13px;
            line-height: 34px;
            position:relative;
            text-align: center;
            border-radius: 34px;
            text-transform:capitalize;
            @include transition(all,0.2s,ease-out);

            svg,
            i{
                display:block;
                text-align:center;
                color: $white;
                font-size: inherit;
                line-height: inherit;
            }
            svg{
                width: 34px !important;
                height: 34px;
                padding: 10px;
            }
            &:hover { color: $white; }
        }
    }
}
.rt-inputwithicon.rt-facebook .rt-icon,
.rt-checkbox.rt-facebook,
li.rt-facebook a { background:#3b5999; }
.rt-inputwithicon.rt-twitter .rt-icon,
.rt-checkbox.rt-twitter,
li.rt-twitter a { background:#55acee; }
.rt-inputwithicon.rt-linkedin .rt-icon,
.rt-checkbox.rt-linkedin,
li.rt-linkedin a { background:#0077B5; }
.rt-inputwithicon.rt-skype .rt-icon,
.rt-checkbox.rt-skype,
li.rt-skype a { background:#00aff0; }
.rt-checkbox.rt-dropbox,
li.rt-dropbox a { background:#007ee5; }
.rt-checkbox.rt-vimeo,
li.rt-vimeo a { background:#1ab7ea; }
.rt-checkbox.rt-tumblr,
li.rt-tumblr a { background:#34465d; }
.rt-checkbox.rt-yahoo,
li.rt-yahoo a { background:#410093; }
.rt-inputwithicon.rt-googleplus .rt-icon,
.rt-checkbox.rt-googleplus,
li.rt-googleplus a { background:#dd4b39; }
.rt-inputwithicon.rt-pinterestp .rt-icon,
.rt-checkbox.rt-pinterestp,
li.rt-pinterestp a { background:#bd081c; }
.rt-checkbox.rt-youtube,
li.rt-youtube a { background:#cd201f; }
.rt-checkbox.rt-stumbleupon,
li.rt-stumbleupon a { background:#eb4924; }
.rt-checkbox.rt-reddit,
li.rt-reddit a { background:#ff5700; }
.rt-checkbox.rt-quora,
li.rt-quora a { background:#b92b27; }
.rt-checkbox.rt-yelp,
li.rt-yelp a { background:#af0606; }
.rt-checkbox.rt-soundcloud,
li.rt-soundcloud a { background:#ff3300; }
.rt-checkbox.rt-whatsapp,
li.rt-whatsapp a { background:#25d366; }
.rt-checkbox.rt-wechat,
li.rt-wechat a { background:#09b83e; }
.rt-checkbox.rt-instagram,
li.rt-instagram a { background:#e4405f; }
.rt-checkbox.rt-dribbble,
li.rt-dribbble a { background:#ea4c89; }
.rt-checkbox.rt-flickr,
li.rt-flickr a { background:#ff0084; }
.rt-checkbox.rt-rss,
li.rt-rss a { background:#ff6600; }
.rt-socialiconsborder li.rt-facebook a {
	color: #3b5999;
	border: 1px solid #3b5999;
}
.rt-socialiconsborder li.rt-twitter a {
	color: #55acee;
	border: 1px solid #55acee;
}
.rt-socialiconsborder li.rt-linkedin a {
	color: #0077B5;
	border: 1px solid #0077B5;
}
.rt-socialiconsborder li.rt-skype a {
	color: #00aff0;
	border: 1px solid #00aff0;
}
.rt-socialiconsborder li.rt-dropbox a {
	color: #007ee5;
	border: 1px solid #007ee5;
}
.rt-socialiconsborder li.rt-vimeo a {
	color: #1ab7ea;
	border: 1px solid #1ab7ea;
}
.rt-socialiconsborder li.rt-tumblr a {
	color: #34465d;
	border: 1px solid #34465d;
}
.rt-socialiconsborder li.rt-yahoo a {
	color: #410093;
	border: 1px solid #410093;
}
.rt-socialiconsborder li.rt-googleplus a {
	color: #dd4b39;
	border: 1px solid #dd4b39;
}
.rt-socialiconsborder li.rt-pinterestp a {
	color: #bd081c;
	border: 1px solid #bd081c;
}
.rt-socialiconsborder li.rt-youtube a {
	color: #cd201f;
	border: 1px solid #cd201f;
}
.rt-socialiconsborder li.rt-stumbleupon a {
	color: #eb4924;
	border: 1px solid #eb4924;
}
.rt-socialiconsborder li.rt-reddit a {
	color: #ff5700;
	border: 1px solid #ff5700;
}
.rt-socialiconsborder li.rt-quora a {
	color: #b92b27;
	border: 1px solid #b92b27;
}
.rt-socialiconsborder li.rt-yelp a {
	color: #af0606;
	border: 1px solid #af0606;
}
.rt-socialiconsborder li.rt-soundcloud a {
	color: #ff3300;
	border: 1px solid #ff3300;
}
.rt-socialiconsborder li.rt-whatsapp a {
	color: #25d366;
	border: 1px solid #25d366;
}
.rt-socialiconsborder li.rt-wechat a {
	color: #09b83e;
	border: 1px solid #09b83e;
}
.rt-socialiconsborder li.rt-instagram a {
	color: #e4405f;
	border: 1px solid #e4405f;
}
.rt-socialiconsborder li.rt-dribbble a {
	color: #ea4c89;
	border: 1px solid #ea4c89;
}
.rt-socialiconsborder li.rt-flickr a {
	color: #ff0084;
	border: 1px solid #ff0084;
}
.rt-socialiconsborder li.rt-rss a {
	color: #ff6600;
	border: 1px solid #ff6600;
}
.rt-socialiconsborder li a{
	background: none;
	line-height: 32px;
}
.rt-socialiconsborder li.rt-facebook a svg,
.rt-socialiconsborder li.rt-facebook a i{ color: #3b5999; }
.rt-socialiconsborder li.rt-twitter a svg,
.rt-socialiconsborder li.rt-twitter a i{ color: #55acee; }
.rt-socialiconsborder li.rt-linkedin a svg,
.rt-socialiconsborder li.rt-linkedin a i{ color: #0077B5; }
.rt-socialiconsborder li.rt-skype a svg,
.rt-socialiconsborder li.rt-skype a i{ color: #00aff0; }
.rt-socialiconsborder li.rt-dropbox a svg,
.rt-socialiconsborder li.rt-dropbox a i{ color: #007ee5; }
.rt-socialiconsborder li.rt-vimeo a svg,
.rt-socialiconsborder li.rt-vimeo a i{ color: #1ab7ea; }
.rt-socialiconsborder li.rt-tumblr a svg,
.rt-socialiconsborder li.rt-tumblr a i{ color: #34465d; }
.rt-socialiconsborder li.rt-yahoo a svg,
.rt-socialiconsborder li.rt-yahoo a i{ color: #410093; }
.rt-socialiconsborder li.rt-googleplus a svg,
.rt-socialiconsborder li.rt-googleplus a i{ color: #dd4b39; }
.rt-socialiconsborder li.rt-pinterestp a svg,
.rt-socialiconsborder li.rt-pinterestp a i{ color: #bd081c; }
.rt-socialiconsborder li.rt-youtube a svg,
.rt-socialiconsborder li.rt-youtube a i{ color: #cd201f; }
.rt-socialiconsborder li.rt-stumbleupon a svg,
.rt-socialiconsborder li.rt-stumbleupon a i{ color: #eb4924; }
.rt-socialiconsborder li.rt-reddit a svg,
.rt-socialiconsborder li.rt-reddit a i{ color: #ff5700; }
.rt-socialiconsborder li.rt-quora a svg,
.rt-socialiconsborder li.rt-quora a i{ color: #b92b27; }
.rt-socialiconsborder li.rt-yelp a svg,
.rt-socialiconsborder li.rt-yelp a i{ color: #af0606; }
.rt-socialiconsborder li.rt-soundcloud a svg,
.rt-socialiconsborder li.rt-soundcloud a i{ color: #ff3300; }
.rt-socialiconsborder li.rt-whatsapp a svg,
.rt-socialiconsborder li.rt-whatsapp a i{ color: #25d366; }
.rt-socialiconsborder li.rt-wechat a svg,
.rt-socialiconsborder li.rt-wechat a i{ color: #09b83e; }
.rt-socialiconsborder li.rt-instagram a svg,
.rt-socialiconsborder li.rt-instagram a i{ color: #e4405f; }
.rt-socialiconsborder li.rt-dribbble a svg,
.rt-socialiconsborder li.rt-dribbble a i{ color: #ea4c89; }
.rt-socialiconsborder li.rt-flickr a svg,
.rt-socialiconsborder li.rt-flickr a i{ color: #ff0084; }
.rt-socialiconsborder li.rt-rss a svg,
.rt-socialiconsborder li.rt-rss a i{ color: #ff6600; }
.rt-roundicontext {
    @include transition(all,0.2s,ease-out);

    span { padding: 0 15px 0 0; }

    &:focus,
    &:hover {
        color: $white;
        width: 100%;
    }
}
.rt-roundicontext .rt-usericonholder {
	width: 250px;
	display: block;
	overflow: hidden;
	font-style: normal;
	@include transition(all,0.2s,ease-out);
}
.rt-socialsharewithtext .rt-usericonholder svg,
.rt-socialsharewithtext .rt-usericonholder span {
	float:left;
	font-size: inherit;
	line-height: inherit;
}
.rt-roundicontext:hover .rt-usericonholder { width: 100%; }
.rt-bgfern{background: $fern !important;}
.rt-bgforestgreen{background: $forestgreen !important;}
.rt-bgredorange{background: $redorange !important;}
.rt-description {
	width: 100%;
	float: left;
}
.rt-stars span,
.rt-stars {
	width:100px;
	height: 16px;
	float: left;
	line-height:16px;
    position:relative;

    span:after,
    &:before {
        top: 0;
        left: 0;
        font-size: 16px;
        line-height: 16px;
        position: absolute;
        letter-spacing: 4px;
        font-family: 'FontAwesome';
        color: rgba(218, 218, 218, .8);
        content: '\f005 \f005 \f005 \f005 \f005';
    }

    span {
        width:75%;
        overflow: hidden;

        &:after {color:#000;}
    }
}
.row.no-gutters {
	margin-right: 0;
	margin-left: 0;
}
.no-gutters {
	margin-right: 0;
	margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
	padding-right: 0;
	padding-left: 0;
}
.rt-dropdowarrow{
	top:9px;
	right:15px;
	color:#fff;
	width:20px;
	height:20px;
	display:none;
	cursor:pointer;
	font-size:16px;
	line-height:20px;
	position:absolute;
    text-align:center;

    i{
        display: block;
        font-size: inherit;
        line-height: inherit;
    }
}
.rt-open .rt-dropdowarrow i:before{content: '\f106';}
.menu-item-has-children,
.menu-item-has-mega-menu{ position: relative; }
.mega-menu-row{
	width: 100%;
	float: left;
	display: table;
	table-layout: fixed;
}
.mega-menu-col{
	display: table-cell;
	vertical-align: top;
}
.navbar-toggle {
	border: 0;
	margin: 3px 0;
	border-radius: 0;
	padding: 10px 12px;
}
.navbar-toggle .icon-bar{background: #fff;}
.rt-formtheme {
    width:100%;
	float:left;

    fieldset{
        border:0;
        margin:0;
        padding:0;
        width:100%;
        float:left;
        position:relative;

        > label{
            color: $black;
            font-size: 16px;
            font-weight: 400;
            line-height: 14px;
        }
        textarea{width: 100%;}
        textarea,
        .form-control{
            color: $tuna;
            height: 50px;
            font-size: 16px;
            font-weight: 400;
            @include bd-radius(6px);
        }
    }
}
.rt-inputwithicon{
    position:relative;

    i{
        top:0;
        right:0;
        z-index:2;
        width: 40px;
        height: 40px;
        cursor: pointer;
        font-size: 18px;
        line-height: 40px;
        position:absolute;
        text-align: center;
        color: $lightgreytwo;
    }

    .form-control{
        z-index:1;
        position:relative;
    }
}
/*==========================================================================================
		For Mobile Nav use this code in Responsive.css desired Query
==========================================================================================*/
/*
.navbar-toggle{display: block;}
.navbar-collapse.collapse {display: none!important;}
.collapse.in{display:block !important;}
*/
/*==========================================================================================
			For Mobile Nav use this code in Responsive.css desired Query
==========================================================================================*/
/* =============================================
			Theme Pagination Style
============================================= */
.rt-pagination {
	width:100%;
    float: left;
    margin: 30px 0 0;
    font:16px/52px 'Product Sans', Arial, Helvetica, sans-serif;

    ul {
        margin:0;
        padding:0;
        float:right;
        list-style:none;
        text-align: center;
        line-height: inherit;

        li {
            float:left;
            line-height: inherit;
            list-style-type:none;
            border-left: 1px solid $whisperthree;

            a,
            span{
                width:50px;
                height: 50px;
                display:block;
                color: $mallard;
                text-align:center;
                position:relative;
                background: $white;
                line-height: inherit;
            }
        }
        li.rt-active {

            a{
                color: $white;
                background: $harleydavidsonorange;
            }
        }
        li.rt-prevpage {
            a{@include bd-radius(6px 0 0 6px);}
        }
        li.rt-nextpage {
            a{@include bd-radius(0 6px 6px 0);}
        }
    }
}
/* =============================================
			Theme BreadCrumb Style
============================================= */
.rt-breadcrumb {
    margin:0;
	padding: 0;
	float: left;
    width: 100%;
	list-style: none;
	text-align: left;
    font: 16px/19px 'Product Sans', Arial, Helvetica, sans-serif;

    li {
        width:auto;
        list-style-type:none;
        display: inline-block;
        vertical-align: middle;
        line-height:inherit;
        position:relative;

        &:after {
            top: 50%;
            left: 8px;
            width: 4px;
            content:'';
            height: 4px;
            position:absolute;
            background: $gunsmoke;
            @include bd-radius(50%);
            -moz-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}
.rt-breadcrumb li:last-child { padding: 0 0 0 25px;}
.rt-breadcrumb li:first-child:after { display: none; }
.rt-breadcrumb li a {
	display:block;
	color:$mallard;
}
/* =============================================
			Theme Styling
============================================= */
.rt-wrapper {
    overflow: hidden;
    padding: 100px 30px 30px 290px;
}
.rt-flexcontainer {
    height: 100vh;
    overflow: hidden;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
}
.rt-flexcontainercolumn {
    display: -webkit-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
/* =============================================
			Login Style
============================================= */
.rt-loginholder{
    height: 100vh;
    overflow: hidden;
    position: relative;
	background: url(assets/images/login-bg.png) no-repeat 0 0/cover;
}
.rt-split{
    z-index: 1;
    width: 100%;
    float: left;
    height: 100%;
    position: relative;
}
.rt-formbox{
    width: 100%;
    padding: 50px;
	overflow: auto;
    margin: 0 auto;
    max-width: 650px;
	text-align: left;
	background: $white;
	@include bd-radius(18px);

    &::-webkit-scrollbar {display: none;}

    .rt-formtheme{

        fieldset{
            label{margin: 0 0 15px;}
            .form-control{
                padding: 10px 20px;
                border-color: $silver;
            }
            .rt-checkbox{
                width: auto;

                label{
                    margin: 0;
                    line-height: 20px;
                    padding-left: 30px;
                }
                input[type=checkbox] + label:before{
                    top: 0;
                    width: 20px;
                    content: '';
                    height: 20px;
                    color: #373542;
                    @include bd-radius(5px);
                    background: $white;
                    border: 2px solid $whisper;
                    background-position: 50% 50%;
                }
                input[type=checkbox]:checked + label:before{
                    border-color: $redorange;
                    background: $redorange url(assets/images/icons/check-icon.png) no-repeat 50% 50%;
                }
            }
            .rt-btn{
                font-size: 18px;
                line-height: 60px;
            }
            .form-group{
                span{
                    color: $black;
                    display: block;
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0 0 10px;
                    line-height: 18px;

                    a{
                        display: inline;
                        color: $dodgerblue;
                    }
                }
                em{
                    display: block;
                    color: #dc3545;
                    margin: 5px 0 0px;
                }
            }
            .form-group:nth-child(1),
            .form-group:nth-child(2){margin: 0 0 30px;}
            .form-group:nth-child(3),
            .form-group:nth-child(4){margin: 0 0 40px;}
            .form-group:last-child{margin: 0;}
        }
    }
	.rt-logo{
		float: none;
		width: 250px;
		display: block;
		margin: 0 auto;
	
		a,
		img{display: block;}
		img{
			width: 100%;
			height: 100%;
		}
	}
}
.rt-loginlogobox{
    width: 100%;
    float: left;
    margin: 0 0 60px;

    .rt-loader{margin: 1rem auto 0;}
}
.rt-logo{
    float: left;
    width: 198px;

    a,
    img{display: block;}
    img{
        width: 100%;
        height: 100%;
    }
}
.rt-backtologin,
.rt-btnforgotpassword{
    color: $boulder;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    :focus,
    &:hover{color: $boulder;}
}
.rt-btnforgotpassword{float: right;}
/* =============================================
		Forgot Password Style
============================================= */
/* =============================================
			Header Style
============================================= */
.rt-header {
    top: 0;
    left: 0;
	z-index: 10;
    position: fixed;
    padding: 15px 20px;
    background: $tangaroa;
}
.rt-topbar{
    width: 100%;
    float: left;

    .rt-logo{margin: 1px 0;}
}
.rt-btnopensidebarmenu{
    float: left;
    color: $white;
    font-size: 20px;
    line-height: 20px;
    margin: 9px 0 9px 30px;

    &:focus,
    &:hover{color: $white;}
}
.rt-userprofileimg{
    width: 40px;
    height: 40px;
    margin: 0 20px 0 0;
    display: inline-block;
    vertical-align: middle;

    img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        @include bd-radius(50%);
    }
}
.rt-themedropdown{
    display: inline-block;
    vertical-align: middle;
}
.rt-manageprofiledropdown{float: right;}
.rt-btndropdown{
    border: 0;
    padding: 0;
    font-size: 16px;
    background: none;
    line-height: 19px;
    color: $white;

    em{
        display: inline-block;
        vertical-align: middle;
        width: 140px;
        overflow: hidden;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        white-space: break-spaces;
        -webkit-box-orient: vertical;
    }
    &:hover,
    &:focus{
        border: 0;
        background: none;
        box-shadow: none !important;
    }
    &:after {display: none;}
}
.btn-success:not(:disabled):not(.disabled).active, 
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle{
    border: 0;
    background: none;
    box-shadow: none !important;
}
.rt-themedropdownmenu {
    margin: 0;
    border: 0;
    padding: 0;
    width: 230px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    background: $white;
    @include bd-radius(12px);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

    ul{
        margin: 0;
        width: 100%;
        float: left;
        line-height: inherit;
        list-style-type: none;

        li{
            width: 100%;
            float: left;
            padding: 14px;
            list-style: none;
            line-height: inherit;

            a{
                padding: 0;
                display: block;
                color: $mallard;

                &:hover,
                &:focus{
                    color: $mallard;
                    background: none;
                }
            }
        }
        li + li{border-top: 1px solid $whisperthree;}
    }
    &:after {
        width: 0;
        height: 0;
        top: -8px;
        right: 20px;
        content: '';
        position: absolute;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
    }
}
.rt-manageprofiledropdownmenu{
    opacity: 0;
    visibility: hidden;
    left: -20px!important;
    top: 100px !important;
    transform: none !important;
    @include transition(all,0.3s,ease-out);
}
.rt-manageprofiledropdown.show .rt-manageprofiledropdownmenu{
    opacity: 1;
    visibility: visible;
    top: 100% !important;
}
.rt-btnholder{
    width: 100%;
    float: left;
}
/* =============================================
			Sidebar Style
============================================= */
.rt-sidebarnavigation{
    left: 0;
    top: 70px;
    z-index: 999;
    width: 250px;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    background: $tangaroa;
    @include transition(all,0.3s,ease-out);
}
.rt-sidebar{
    width: 100%;
    float: left;
    overflow: auto;
    height: calc(100vh - 70px);

    &::-webkit-scrollbar {display: none;}
}
.rt-navigation{
	width: 100%;
    float: left;
}
.rt-sidebarnav{
	width: 100%;
	float: left;
	text-transform: capitalize;
    font:400 16px/19px 'Product Sans', Arial, Helvetica, sans-serif;

    ul{
        width: 100%;
        float: left;
        text-align: left;
        list-style: none;
        line-height: inherit;

        li{
            width: 100%;
            float: left;
            line-height: inherit;
            list-style-type: none;

            a{
                display: block;
                text-align: left;
                overflow: hidden;
                color: $white;
                position: relative;
                white-space: nowrap;

                i{
                    z-index: 11;
                    display: inline-block;
                    vertical-align: middle;
                }
                span{
                    /* top: 1px;
                    right: -22px;
                    position: relative;
                    @include transition(all,0.3s,ease-out); */
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            &.rt-active {
                > a{
                    color: $white;

                    .rt-submenu{

                        &.rt-active{
                            a{color: $white;}
                        }
                    }
                    &:after{
                        right: 0;
                        width: 0;
                        top: 8px;
                        height: 0;
                        content: '';
                        position: absolute;
                        border-right: 15px solid $whisper;
                        border-top: 15px solid transparent;
                        border-bottom: 15px solid transparent;
                    }
                }
            }
        }
    }
}
.rt-sidebarnav {

    > ul{
        > li{

            > a{padding: 15px 25px;}
        }
    }
}
.rt-hasdropdown{position: relative;}
.rt-submenu,
.mega-menu{
    width: 100%;
    float: left;
}
.rt-submenu{
    margin: 0;
    display: none;
}
/* =============================================
			Dashboard Style
============================================= */
.rt-dashboard,
.rt-content{
    width: 100%;
    float: left;
}
.rt-themehead{
    float: left;
    width: 100%;
    margin: 0 0 30px;

    h2{
        margin: 0;
        float: left;
        font-size: 36px;
        color: $midnight;
        font-weight: bold;
        line-height: 37px;
    }
}
.rt-contentbox{
    width: 100%;
    float: left;
    background: $white;
    position: relative;
    @include bd-radius(12px);
    box-shadow: 0px 10px 30px rgba(57, 58, 56, 0.03);
}
.rt-themetitle{
    width: 100%;
    float: left;
    padding: 20px 30px;

    h2{
        margin: 0;
        float: left;
        font-size: 20px;
        color: $midnight;
        line-height: 24px;
        font-weight:  bold;
    }
}
.rt-linkedsupportholder{
    z-index: 1;
    clear: both;
    display: grid;
    grid-gap: 30px;
    position: relative;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

    .rt-contentbox{
        min-height: 295px;
        padding: 20px 30px;
    }
    .rt-themetitle{padding: 0 0 20px;}
}
.rt-linkedbox{

    ul{
        width: 100%;
        float: left;
        height: 200px;
        overflow: auto;
        line-height: inherit;
        list-style-type: none;

        &::-webkit-scrollbar {display: none;}
        li{
            width: 100%;
            float: left;
            padding: 10px 0;
            list-style: none;
            line-height: inherit;

            &:nth-child(1){padding-top: 0;}
            a,
            > span{
                i{
                    font-size: 22px;
                    line-height: 22px;
                    margin: 0 10px 0 0;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            > span{
                color: $black;
                font-size: 16px;
                font-weight: 500;
                line-height: 18px;

                &:first-child{
                    float: left;
                    width: calc(100% - 120px);
                }
                &:nth-child(2){
                    display: inline-block;
                    vertical-align: text-top;
                }
            }
            a{
                
                &:first-child{
                    float: left;
                    padding: 7px 0;
                    color: $mallard;
                    font-size: 16px;
                    line-height: 18px;
                    width: calc(100% - 126px);

                    span{
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
            .rt-btn{
                padding: 0 22px;
                font-size: 14px;
                line-height: 36px;
            }
        }
        li + li{border-top: 1px solid $whisperthree;}
    }
}
.rt-supportbox{
    .rt-btn{
        i{margin: 0 10px 0 0;}
    }
    .rt-btn + .rt-btn{margin: 10px 0 0;}
}
.rt-communitybox{

    .rt-btnholder{margin: 15px 0 0;}
}
.rt-description{
    p{
        margin: 0;
        color: $black;
        font-size: 16px;
        overflow: hidden;
        line-height: 26px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;

        span{display: block;}
    }
}
i.fa-youtube{color: $red;}
i.fa-twitch{color: $blueviolet;}
i.fa-facebook,
i.fa-facebook-square{color: $cerulean;}
/* =============================================
        Manage Profile Style
============================================= */
.rt-manageprofile{
    width: 100%;
    float: left;
}
.rt-manageprofileform{
    padding: 40px;
    background: $white;
    @include bd-radius(12px);

    fieldset{
        .rt-btnbox{margin: 30px 0 0;}
        .rt-btn + .rt-btn{margin: 0 0 0 24px;}
    }
}
.rt-dpuploadbox{
    float: left;
    width: 180px;
    height: 180px;
    position: relative;

    input{display: none;}
}
.rt-uploadprofileimage{
    margin: 0;
    width: 100%;
    float: left;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
    position: relative;
    text-align: center;
    @include bd-radius(12px);
    background-repeat: no-repeat !important;
    background: $lightgrey 50% 50% / cover;
}
.rt-profileimageholder {
    width: 100%;
    float: left;
    overflow: hidden;
    max-width: 225px;
    margin: 0 50px 0 0;
}
.rt-profileimage {
    float: left;
    width: 225px;
    height: 225px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    @include bd-radius(12px);

    img {
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        object-fit: cover;
    }
}
.rt-selectimage {
    width: 100%;
    float: left;
    padding: 30px 0 0;
    text-align: center;

    input {display: none;}
    label {
        margin: 0 auto;
        font-size: 16px;
        padding: 0 20px;
        cursor: pointer;
        max-width: 210px;
        color: $redorange;
        line-height: 50px;
        background: #fff;
        border-radius: 8px;
        text-align: center;
        border: 1px solid $redorange;

        &:hover,
        &:focus{
            color: $white;
            background: $redorange;
        }
    }
}
.rt-profilebox{
    float: left;
    padding: 8px 0;
    width: calc(100% - 275px);
}
.rt-profiletitle{
    width: 100%;
    float: left;
    margin: 0 0 20px;

    h3{
        margin: 0;
        color: $mallard;
        font-size: 20px;
        line-height: 21px;
    }
}
.rt-twocols{
    clear: both;
    margin: 0 -15px;
    overflow: initial;

    .form-group{
        width: calc(50% - 30px);
        margin: 0 15px 20px !important;
    }
    &:after{
        clear: both;
        content: '';
        display: block;
    }
}
.rt-threecols{
    clear: both;
    margin: 0 -15px;
    overflow: initial;

    .form-group{
        width: calc(33.33% - 30px);
        margin: 0 15px 20px !important;
    }
    &:after{
        clear: both;
        content: '';
        display: block;
    }
}
.rt-btnwithoutbg{
    outline: none;
    background: none;
    color: $redorange;
    line-height: 41px;
    border: 1px solid $redorange;

    &:hover,
    &:focus{
        color: $white;
        box-shadow: none;
        background: $redorange;
        border: 1px solid $redorange;
    }
}
.rt-handlebtnsubmit{line-height: 38px;}
/* =============================================
        Billing Info Style
============================================= */
.rt-billinginfo{
    width: 100%;
    float: left;
}
.rt-billinginfoform{
    float: none;
    margin: 0 auto;
    overflow: hidden;
    max-width: 850px;

    &:after{
        content: '';
        clear: both;
        display: block;
    }

    .form-group{margin: 0 0 60px;}
    em{
        font-size: 20px;
        color: $redorange;
        line-height: 24px;
    }
}
.rt-pricingcombinationholder{
    z-index: 1;
    clear: both;
    display: grid;
    grid-gap: 30px;
    position: relative;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

    .rt-radio{
        label{padding: 0;}
    }
    .rt-radio input[type=radio] + label:before,
    .rt-checkbox input[type=checkbox] + label:before {
        left: auto;
        right: 20px;
    }
}
.rt-pricingtablebox{
	width: 100%;
	float: left;
    min-height: 515px;
	position: relative;
	text-align: center;
	border-radius: 12px;
	background: $white;
	-webkit-box-shadow: 0 0 13px 0 rgba(0,0,0,0.13);
	box-shadow: 0 0 13px 0 rgba(0,0,0,0.13);

    .rt-btnbox{
        margin: 20px 0;

        .rt-btncancel{padding: 0 36px;}
    }
}
.rt-pricingtabelehead{
	width: 100%;
	float: left;
	padding: 30px 0;
    min-height: 204px;
	position: relative;
    background: $redorange;
    @include bd-radius(12px 12px 0 0);

    h2{
        margin: 0;
        font-size: 60px;
        line-height: 60px;
        font-style: normal;
        color: $white;

        span{
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }
    }
    em{
        color: $white;
        display: block;
        font-size: 22px;
        font-weight: 500;
        margin: 0 0 15px;
        line-height: 24px;
    }
    h3{
        margin: 0;
        color: $white;
        font-size: 20px;
        line-height: 23px;
        display: inline-block;
        vertical-align: middle;
    }
    h4{
        color: $white;
        font-size: 22px;
        margin: 15px 0 0;
        line-height: 26px;
        display: inline-block;
        vertical-align: middle;
    }
    .rt-selectedpriceplan{
        margin: 0;
        top: 10px;
        left: 10px;
        width: 90px;
        font-size: 14px;
        line-height: 32px;
        position: absolute;
        @include bd-radius(4px);
    }
}
.rt-priceswtich{
    width: 100%;
    float: left;
    margin: 20px 0 0;
}
.rt-priceswitch{
	width: 40px;
    margin: 0 8px;
    position: relative;
    display: inline-block;
    vertical-align: middle;

	.rt-priceswitch-checkbox {display: none;}
	.rt-priceswitch-label {
		margin: 0;
		padding: 0;
		height: 24px;
		display: block;
		cursor: pointer;
		line-height: 24px;
		position: relative;
		@include bd-radius(27px);
		background: $verylightgrey;
		transition: background-color 0.3s ease-in;

		&:before {
			top: 4px;
			bottom: 0;
			margin: 0;
			left: 4px;
			content: "";
			width: 16px;
			height: 16px;
			display: block;
			position: absolute;
			background: $white;
			@include bd-radius(50%);
			-webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
			box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
			transition: all 0.2s ease-in 0s;
		}
	}
}
.rt-priceswitch-checkbox:checked + .rt-priceswitch-label{
	background: $black;
	border-color: $black;

	&:before {
        -webkit-transform: translateX(16px);
        transform: translateX(16px);
    }
}
.rt-pricingtablecontent{
	width: 100%;
	float: left;

    ul{
        width: 100%;
        float: left;
        font-size: 16px;
        list-style: none;
        line-height: 16px;

        li{
            width: 100%;
            float: left;
            padding: 20px 0;
            line-height: inherit;
            list-style-type: none;

            span{
                display: block;
                color: $dimgray;

                em{
                    color: $black;
                    font-weight: 700;
                }
            }
            h3{
                margin: 0;
                font-size: 18px;
                color: $dimgray;
                font-weight: 500;
                line-height: 20px;
            }
            /* &:last-child{
                padding: 20px 0 40px;
                @include bd-radius(0 0 12px 12px);
            } */
        }
        li.rt-odd{background: #f3f3f3;}
        li.rt-even{background: #fafafa;}
    }
}
.rt-btnbillinginfo{
    padding: 0;
    min-width: 180px;
}
.rt-selectedpriceplan{
    width: 121px;
    color: $white;
    font-size: 16px;
    line-height: 43px;
    margin: 0 10px 0 0;
    cursor: context-menu;
    display: inline-block;
    vertical-align: middle;
    @include bd-radius(6px);
    background: $forestgreen;
}
.rt-btndisabled{
    background: #9e9e9e;

    &:hover,
    &:focus{background: #9e9e9e;}
}
/* =============================================
        Latest Stream Style
============================================= */
.rt-lateststream{
    width: 100%;
    float: left;

    .rt-loader{
        top: 50%;
        margin: 0;
        left: 50%;
        position: absolute;
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }
}
.rt-streamingproviders{
    z-index: 1;
    clear: both;
    display: grid;
    grid-gap: 30px;
    position: relative;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
.rt-streamingprovider{
    text-align: center;
    position: relative;
    padding: 20px 20px 30px;
}
.rt-selectedicon{
    top: 10px;
    right: 10px;
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    color: $forestgreen;
}
.rt-streamingimg{
    width: 250px;
    height: 85px;
    display: block;
    margin: 0 auto 20px;

    a,
    img{display: block;}
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.rt-streamingproviderbtnbox{
    width: 100%;
    float: left;
    text-align: center;
}
.rt-themetablebox{
    width: 100%;
    float: left;

    .rt-contentbox{min-height: 680px;}
    .table{
        margin: 0;

        thead{
            tr{
                th{
                    color: $mallard;
                    font-size: 16px;
                    text-align: left;
                    font-weight: bold;
                    line-height: 18px;
                    padding: 15px 20px;
                    background: $white;
                    white-space: nowrap;
                    border: 0 !important;
                    vertical-align: middle;

                    &:first-child{@include bd-radius(12px 0 0 0);}
                    &:last-child{@include bd-radius(0 12px 0 0);}
                }
            }
        }
        tbody{
            tr{
                td{
                    border: 0;
                    text-align: left;
                    padding: 15px 20px;
                    white-space: nowrap;
                    vertical-align: middle;

                    p{
                        margin: 0;
                        float: left;
                        width: 350px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        text-overflow: ellipsis;
                        white-space: break-spaces;
                        -webkit-box-orient: vertical;
                    }
                    em{display: block;}
                    p,
                    > em,
                    time{
                        color: $pewter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        font-style: normal;
                    }
                    time{
                        display: inline-block;
                        vertical-align: middle;
                    }
                    h3{
                        margin: 0;
                        color: $mallard;
                        font-size: 16px;
                        line-height: 19px;
                        text-transform: inherit;

                        em{
                            color: $gunsmoke;
                            padding: 8px 0 0;

                            /* i{
                                color: $fallow;
                                margin: 0 12px 0 0;
                            } */
                        }
                    }
                    .rt-btn{
                        padding: 0;
                        font-weight: 400;
                        line-height: 40px;

                        i{
                            font-size: 20px;
                            line-height: 20px;
                            margin: 0 5px 0 0;
                        }
                    }
                    .rt-btn + .rt-btn{margin: 0 0 0 10px;}
                }
            }
            tr + tr{border-top: 1px solid $whisperthree;}
        }
    }
}
.rt-channeltagsbox{
    width: 250px;
    margin: 0 auto;
    overflow: hidden;
    line-height: initial;
    display: -webkit-box;
    list-style-type: none;
    -webkit-line-clamp: 2;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: break-spaces;
    -webkit-box-orient: vertical;

    li{
        list-style: none;
        padding: 0 5px 0 0;
        line-height: initial;
        display: inline-block;
        vertical-align: middle;

        &:last-child{padding: 0;}
    }
}
.rt-channeltags{
    font-size: 14px;
    padding: 0 12px;
    line-height: 22px;
    color: $white;
    display: inline-block;
    vertical-align: middle;
    background: $redorange;
    @include bd-radius(6px);
}
.rt-channelsiconbox{
    line-height: initial;
    list-style-type: none;
    display: inline-block;
    vertical-align: middle;

    li{
        list-style: none;
        padding: 0 8px 0 0;
        line-height: initial;
        display: inline-block;
        vertical-align: middle;

        i{
            cursor: pointer;
            font-size: 22px;
            line-height: 22px;
        }
        &:last-child{padding: 0;}
    }
}
/* =============================================
        Latest Stream Detail Style
============================================= */
.rt-streamdetail{
    width: 100%;
    float: left;
}
.rt-btnleftarrow{
    float: left;
    padding: 0 20px;
    line-height: 38px;
    margin: 0 15px 0 0;
    @include bd-radius(8px);

    i{margin: 0 10px 0 0;}
}
.rt-streamdetailbox{
    margin: 0 auto;
    max-width: 550px;
    overflow: initial;
    text-align: center;

    &:after{
        clear: both;
        content: '';
        display: block;
    }
    .rt-contentbox{
        margin: 0 0 50px;
        padding: 20px 20px 30px;
    }
}
.rt-alert{
    margin: 0;
    width: 100%;
    float: left;
    text-align: center;
    position: relative;
    padding: 10px 20px;
    @include bd-radius(8px);

    span {
        cursor: pointer;
        color: $mallard;
        font-size: 14px;
        line-height: 22px;
        display: inline-block;
        vertical-align: middle;

        em{display: block;}
    }
}
/* =============================================
            Download Style
============================================= */
.rt-downloads{
    width: 100%;
    float: left;
}
.rt-downloadholder{
    width: 100%;
    margin: 0 auto;
    max-width: 500px;
    overflow: hidden;
    text-align: center;

    &:after{
        clear: both;
        content: '';
        display: block;
    }
}
.rt-downloadbox{
    margin: 0 -10px;
    overflow: hidden;
    line-height: inherit;
    list-style-type: none;

    li{
        float: left;
        margin: 0 10px;
        list-style: none;
        line-height: inherit;
        width: calc(50% - 20px);
    }
}
.rt-downloadcontent{
    width: 100%;
    float: left;
    padding: 20px;
    background: $white;
    @include bd-radius(6px);

    a{
        color: $black;
        display: block;
    }
    h3{
        margin: 0;
        color: $black;
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
    }
    i{
        display: block;
        font-size: 60px;
        margin: 0 0 20px;
        line-height: 60px;
    }
    i.fa-android{color: #a4c639;}
}
/* =============================================
        Subscribe Style
============================================= */
.rt-subscribes{
    width: 100%;
    float: left;
}
.rt-subscribeholder{
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    max-width: 950px;
    text-align: center;
}
.rt-subscribe{
    width: 100%;
    float: left;
}
.rt-subscribehead{
    width: 100%;
    float: left;
    margin: 0 0 50px;

    h2{
        font-size: 36px;
        margin: 0 0 25px;
        color: $midnight;
        font-weight: bold;
        line-height: 40px;

        span{
            display: block;
            font-size: 16px;
            font-weight: 400;
            margin: 15px 0 0;
            line-height: 21px;
        }

    }
}
.rt-btncouponcode{
    font-size: 16px;
    padding: 0 20px;
    color: $redorange;
    font-weight: bold;
    line-height: 36px;
    display: inline-block;
    vertical-align: middle;
    @include bd-radius(27px);
    border: 2px solid $redorange;
}
.rt-subscriptionplan{
    width: 100%;
    float: left;
    margin: 0 0 70px;

    h3{
        font-size: 24px;
        margin: 0 0 30px;
        color: $midnight;
        font-weight: 500;
        line-height: 27px;
    }
    ul{
        width: 300px;
        margin: 0 auto;
        overflow: hidden;
        line-height: inherit;
        list-style-type: none;

        li{
            width: 100%;
            float: left;
            list-style: none;
            line-height: inherit;

            span{
                color: $black;
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;

                &:first-child{float: left;}
                &:last-child{float: right;}
            }
            &:nth-child(2){
                padding: 10px 0;
                border-bottom: 1px solid $black;
            }
            &:nth-child(3){padding: 5px 0 0;}
        }
    }
    .rt-btnbox{
        padding: 30px 0 0;

        .rt-btn + .rt-btn{margin: 0 0 0 15px;}
    }
}
/*==========================================================================================
                        Admin Side Style
==========================================================================================*/
/* =============================================
        Admin Dashboard Style
============================================= */
.rt-admindashboard{
    width: 100%;
    float: left;

    .rt-searchform{
        max-width: 400px;
    }

    .rt-loader{
        top: 50%;
        margin: 0;
        left: 50%;
        position: absolute;
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }
}
.rt-actionicons{
    line-height: initial;
    list-style-type: none;
    display: inline-block;
    vertical-align: middle;

    li{
        list-style: none;
        padding: 0 15px 0 0;
        line-height: initial;
        display: inline-block;
        vertical-align: middle;

        i,
        a,
        button{
            color: $pewter;
            display: block;
            font-size: 20px;
            line-height: 20px;
        }
        &:last-child {padding: 0;}
        button{
            border: 0;
            padding: 0;
            background: none;

            &:hover,
            &:focus{
                border: 0;
                color: $pewter;
                background: none;
                box-shadow: none;
            }
        }
        .btn-primary:not(:disabled):not(.disabled):active{
            border: 0;
            color: $pewter;
            background: none;
            box-shadow: none;
        }
    }
}
.rt-statisticsholder{
    width: 100%;
    float: left;
    margin: 0 0 30px;
}
.rt-statistics{
    z-index: 1;
    clear: both;
    display: grid;
    grid-gap: 30px;
    position: relative;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr))
}
.rt-statistic{
    width: 100%;
    float: left;
    padding: 30px;
    @include bd-radius(6px);
    background: $harleydavidsonorange;
}
.rt-statisticcontent{
    float: left;
    padding: 7px 0;
    width: calc(100% - 90px);

    h2{
        margin: 0;
        color: $white;
        font-size: 32px;
        font-weight: bold;
        line-height: 32px;

        em{
            display: block;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            padding: 0 0 16px;
            font-style: normal;
        }

    }
    h3{
        margin: 0;
        color: $pewter;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        padding: 10px 0 0;
        font-style: normal;
    }
}
.rt-statisticicon{
    width: 80px;
    float: right;
    height: 80px;
    color: $white;
    font-size: 40px;
    line-height: 80px;
    text-align: center;
    @include bd-radius(8px);
    background: rgba(0 , 0 , 0, 0.2);
}
.rt-activeusers{
    .rt-statisticicon{color: #1cdc1c;}
}
.rt-inactiveusers{
    .rt-statisticicon{color: $dimgray;}
}
.rt-searchbox{
    width: 100%;
    float: left;
    margin: 0 0 20px;
}
.rt-rightarea{float: right;}
.rt-searchform{
    float: right;
    max-width: 600px;
    
    fieldset{
        .form-control{height: 40px;}
    }
    .rt-twocols{
        margin: 0 -8px;
        width: calc(100% - 90px);

        .form-group{
            margin: 0 8px !important;

            &:first-child{width: calc(60% - 16px);}
            &:nth-child(2){width: calc(40% - 16px);}
            .rt-select{
                width: calc(100% - 115px);

                select{
                    border: 0;
                    padding: 0;
                    color: $nero;
                    font-size: 16px;
                    background: none;
                }
                &:after{display: none;}
            }
            span{
                color: $nero;
                font-size: 16px;
                line-height: 38px;
                margin: 0 0 0 15px;
                display: inline-block;
                vertical-align: middle;
                width: 100px;
                overflow: hidden;
                -webkit-line-clamp: 1;
                text-overflow: ellipsis;
                white-space: break-spaces;
                -webkit-box-orient: vertical;
            }
        }
    }
}
.rt-btnfilters{
    top: 0;
    right: 0;
    border: 0;
    padding: 0;
    width: 90px;
    line-height: 40px;
    position: absolute;

    &:focus,
    &:hover{
        border: 0;
        box-shadow: none;
        background: $harleydavidsonorange;
    }
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle{
    border: 0;
    box-shadow: none !important;
    background: $harleydavidsonorange;
}
/* =============================================
        Edit Delete Modal Style
============================================= */
.rt-thememodal{
    padding: 0 !important;

    .modal-dialog {
        max-width: 638px;
        overflow: hidden;
    }
    .modal-body,
    .modal-content{
        padding: 0;
        width: 100%;
        float: left;
    }
    .modal-content{
        border: 0;
        background: $white;
        @include bd-radius(12px);
    }
}
.rt-thememodalbox{
    width: 100%;
    float: left;
}
.rt-thememodalhead{
    width: 100%;
    float: left;
    margin: 0 0 25px;
    text-align: center;

    h2{
        margin: 0;
        color: $nero;
        font-size: 24px;
        font-weight: 700;
        line-height: 26px;
    }
}
.rt-thememodalcontent{
    width: 100%;
    float: left;
    padding: 30px;

    .rt-formtheme{
        fieldset{

            .form-group{margin: 0 0 25px;}
            /* .form-group:nth-child(3){margin: 0 0 40px;} */
            .form-control{
                height: 50px;
                font-size: 15px;
                font-weight: 500;
                @include bd-radius(8px);
            }
        }
    }
    .rt-btnbox{
        .rt-btn{
            border: 0;
            padding: 0;
            width: 180px;

            &:hover,
            &:focus{
                box-shadow: none;
                background: $harleydavidsonorange;
            }
        }
        .btn-primary:not(:disabled):not(.disabled):active{
            border: 0;
            color: $white;
            box-shadow: none;
            background: $harleydavidsonorange;
        }
        .rt-btn + .rt-btn{margin: 0 0 0 8px;}
    }
}
.rt-btnbox{
    width: 100%;
    float: left;
}
.rt-btn-green{
    background-color: #09b83e;
}
/* =============================================
        Filters Modal Style
============================================= */
.rt-filtermodalmodal{
    .modal-dialog {max-width: 780px;}
}
/* .rt-thememodalhead{
    width: 100%;
    float: left;
    margin: 0 0 20px;

    h2{
        margin: 0;
        color: $nero;
        font-size: 20px;
        line-height: 24px;
    }
} */
/* =============================================
        Admin Stream Detail Style
============================================= */
.rt-paymenthistory{
    width: 100%;
    float: left;
}
.rt-statustext{
    width: 83px;
    color: $white;
    font-size: 14px;
    line-height: 26px;
    background: $pewter;
    display: inline-block;
    vertical-align: middle;
    @include bd-radius(27px);
}
/* =============================================
        Stream Statistic Style
============================================= */
.rt-streamstatistic{
    width: 100%;
    float: left;
}
.rt-streamstatisticholder{
    z-index: 1;
    clear: both;
    display: grid;
    grid-gap: 30px;
    position: relative;
    grid-template-columns: repeat(auto-fill,minmax(400px,1fr));

    .rt-contentbox{
        padding: 15px;
        height: 330px;
    }
    .rt-themetitle{padding: 0;}
}
/* =============================================
        Change Password Modal Styling
============================================= */
.rt-changepasswordmodal{

    .modal-dialog {max-width: 545px;}
    .modal-content{@include bd-radius(16px);}
}
.rt-changepasswordmodalbox{

    .rt-thememodalhead{
        margin: 0;
        padding: 16px;
        background: $redorange;
        @include bd-radius(16px 16px 0 0);
    
        h2{
            color: $white;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
        }
    }
    .rt-thememodalcontent{
        padding: 20px 30px 24px;
    
        .rt-formtheme{
            fieldset{
                legend{
                    color: #dc3545;
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0 0 20px;
                    line-height: 24px;
                }
                .form-group{margin: 0 0 18px;}
                .form-control{
                    font-size: 18px;
                    font-weight: 400;
                    padding: 20px 15px;
                }
            }
        }
        .rt-btnbox{
            .rt-btn + .rt-btn{margin: 0 0 0 24px;}
        }
    }
}
/* =============================================
            Loader Style
============================================= */
.rt-loader {
	margin: 1rem auto;
	display: table;

	.la-line-scale-party,
	.la-line-scale-party > div {
		position: relative;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.la-line-scale-party {
		display: block;
		font-size: 0;
		color: $redorange;
	}
	.la-line-scale-party.la-dark {
		color: #333;
	}
	.la-line-scale-party > div {
		display: inline-block;
		float: none;
		background-color: currentColor;
		border: 0 solid currentColor;
	}
	.la-line-scale-party {
		width: 50px;
		height: 30px;
	}
	.la-line-scale-party > div {
		width: 7px;
		height: 32px;
		margin: 3px;
		margin-top: 0;
		margin-bottom: 0;
		border-radius: 5px;
		-webkit-animation-name: line-scale-party;
		-moz-animation-name: line-scale-party;
		-o-animation-name: line-scale-party;
		animation-name: line-scale-party;
		-webkit-animation-iteration-count: infinite;
		-moz-animation-iteration-count: infinite;
		-o-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}

	.la-line-scale-party > div:nth-child(1) {
		-webkit-animation-duration: 3s;
		-moz-animation-duration: 3s;
		-o-animation-duration: 3s;
		animation-duration: 3s;
		-webkit-animation-delay: -0.2s;
		-moz-animation-delay: -0.2s;
		-o-animation-delay: -0.2s;
		animation-delay: -0.2s;
	}
	.la-line-scale-party > div:nth-child(2) {
		-webkit-animation-duration: 2s;
		-moz-animation-duration: 2s;
		-o-animation-duration: 2s;
		animation-duration: 2s;
		-webkit-animation-delay: -1s;
		-moz-animation-delay: -1s;
		-o-animation-delay: -1s;
		animation-delay: -1s;
	}
	.la-line-scale-party > div:nth-child(3) {
		-webkit-animation-duration: 1s;
		-moz-animation-duration: 1s;
		-o-animation-duration: 1s;
		animation-duration: 1s;
		-webkit-animation-delay: -1s;
		-moz-animation-delay: -1s;
		-o-animation-delay: -1s;
		animation-delay: -1s;
	}
	.la-line-scale-party > div:nth-child(4) {
		-webkit-animation-duration: 1.5s;
		-moz-animation-duration: 1.5s;
		-o-animation-duration: 1.5s;
		animation-duration: 1.5s;
		-webkit-animation-delay: -0.35s;
		-moz-animation-delay: -0.35s;
		-o-animation-delay: -0.35s;
		animation-delay: -0.35s;
	}
	.la-line-scale-party > div:nth-child(5) {
		-webkit-animation-duration: 2s;
		-moz-animation-duration: 2s;
		-o-animation-duration: 2s;
		animation-duration: 2s;
		-webkit-animation-delay: -3s;
		-moz-animation-delay: -3s;
		-o-animation-delay: -3s;
		animation-delay: -3s;
	}

	.la-line-scale-party.la-sm {
		width: 20px;
		height: 16px;
	}
	.la-line-scale-party.la-sm > div {
		width: 2px;
		height: 16px;
		margin: 1px;
		margin-top: 0;
		margin-bottom: 0;
	}
	.la-line-scale-party.la-2x {
		width: 80px;
		height: 64px;
	}
	.la-line-scale-party.la-2x > div {
		width: 8px;
		height: 64px;
		margin: 4px;
		margin-top: 0;
		margin-bottom: 0;
	}

	@-webkit-keyframes line-scale-party {
		0% {
			-webkit-transform: scaleY(1);
			transform: scaleY(1);
		}
		20% {
			-webkit-transform: scaleY(0.3);
			transform: scaleY(0.3);
		}
		40% {
			-webkit-transform: scaleY(0.5);
			transform: scaleY(0.5);
		}
		60% {
			-webkit-transform: scaleY(0.2);
			transform: scaleY(0.2);
		}
		80% {
			-webkit-transform: scaleY(0.6);
			transform: scaleY(0.6);
		}
		100% {
			-webkit-transform: scaleY(1);
			transform: scaleY(1);
		}
	}
	@-moz-keyframes line-scale-party {
		0% {
			-moz-transform: scaleY(1);
			transform: scaleY(1);
		}
		20% {
			-moz-transform: scaleY(0.3);
			transform: scaleY(0.3);
		}
		40% {
			-moz-transform: scaleY(0.5);
			transform: scaleY(0.5);
		}
		60% {
			-moz-transform: scaleY(0.2);
			transform: scaleY(0.2);
		}
		80% {
			-moz-transform: scaleY(0.6);
			transform: scaleY(0.6);
		}
		100% {
			-moz-transform: scaleY(1);
			transform: scaleY(1);
		}
	}
	@-o-keyframes line-scale-party {
		0% {
			-o-transform: scaleY(1);
			transform: scaleY(1);
		}
		20% {
			-o-transform: scaleY(0.3);
			transform: scaleY(0.3);
		}
		40% {
			-o-transform: scaleY(0.5);
			transform: scaleY(0.5);
		}
		60% {
			-o-transform: scaleY(0.2);
			transform: scaleY(0.2);
		}
		80% {
			-o-transform: scaleY(0.6);
			transform: scaleY(0.6);
		}
		100% {
			-o-transform: scaleY(1);
			transform: scaleY(1);
		}
	}
	@keyframes line-scale-party {
		0% {
			-webkit-transform: scaleY(1);
			-moz-transform: scaleY(1);
			-o-transform: scaleY(1);
			transform: scaleY(1);
		}
		20% {
			-webkit-transform: scaleY(0.3);
			-moz-transform: scaleY(0.3);
			-o-transform: scaleY(0.3);
			transform: scaleY(0.3);
		}
		40% {
			-webkit-transform: scaleY(0.5);
			-moz-transform: scaleY(0.5);
			-o-transform: scaleY(0.5);
			transform: scaleY(0.5);
		}
		60% {
			-webkit-transform: scaleY(0.2);
			-moz-transform: scaleY(0.2);
			-o-transform: scaleY(0.2);
			transform: scaleY(0.2);
		}
		80% {
			-webkit-transform: scaleY(0.6);
			-moz-transform: scaleY(0.6);
			-o-transform: scaleY(0.6);
			transform: scaleY(0.6);
		}
		100% {
			-webkit-transform: scaleY(1);
			-moz-transform: scaleY(1);
			-o-transform: scaleY(1);
			transform: scaleY(1);
		}
	}
}

.rt-google-btn{
    display: flex;
    justify-content: center;
    button{
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.googlebtn{
    padding: 4px 0 !important;
    box-shadow: none !important;
    border: 1px solid rgba(0,0,0,0.24) !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 6px !important;
}
.googlebtn span{
    margin: 0 !important;
}





@import "assets/css/responsive.scss";
