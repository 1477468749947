$black: #000;
$white: #fff;
$red: #FF0000;
$tuna: #495057;
$fern: #5CB85C;
$nero: #242424;
$pewter: #9c9d9c;
$silver: #BBBBBB;
$whisper: #e5e5e5;
$dimgray: #6E6E6E;
$mallard: #393a38;
$whisper: #EDEDED;
$mariner: #3B5998;
$midnight: #273240;
$cerulean: #0077B5;
$tangaroa: #052C3b;
$lightgrey: #d7d6d6;
$redorange: #F23329;
$dodgerblue: #007BFF;
$blueviolet: #9146FF;
$verylightgrey: #ccc;
$forestgreen: #228B22;
$lightgreytwo: #d3d3d3;
$harleydavidsonorange: #C8281F;



$blue: #012C6D;
$blueDark: #06294B;
$blueLight: #1565D8;
$blueLight2: #1788FB;
$tan: #cfaf8a;
$delta: #9c9e9a;
$linen: #f9f5f0;
$bombay: #b0b0af;
$fallow: #c39b6d;
$boulder: #7e7f7e;
$concord: #7a7977;
$themecolor: #fff;
$romance: #f3f1ee;
$pinkswan:#b7b7b7;
$linentwo: #f5efe8;
$gunsmoke: #757674;
$cinnabar: #f04B27;
$seashell: #faf8f7;
$lightgrey: #d7d6d6;
$hurricane: #7a7877;
$Whispertwo: #eceaea;
$bridalheath: #f3ebe2;
$aquasqueeze: #d7d8d7;
$whisperfour: #ebebeb;
$whisperfive: #ededed;
$whisperthree: #f0ecec;
$lightgreytwo: #d3d3d3;
$seashelllight: #faf8f7;
$quarterspanishwhite:#E9E0D4;

